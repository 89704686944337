let baseUrl = 'https://api.phyathai2.plaping-dc.com/'
baseUrl = 'https://api.phyathai2.plaping-dc.com/'
const standardCode = 'PT22021'

if (process.env.NODE_ENV === 'production') {
  baseUrl = 'https://api.phyathai2.plaping-dc.com/'
}
export {
  baseUrl,
  standardCode
}
