import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import middlewarePipeline from '@/middlewares/middlewarePipeline'
import auth from '@/middlewares/auth'
import event from '@/middlewares/event'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      layout: 'grey',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      layout: 'login'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
    meta: {
      layout: 'grey',
      middleware: [
        event
      ]
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Account.vue'),
    meta: {
      layout: 'grey',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: () => import('../views/EditProfile.vue'),
    meta: {
      layout: 'grey',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/my-coupon',
    name: 'MyCoupon',
    component: () => import('../views/MyCoupon.vue'),
    meta: {
      layout: 'grey',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/history-coupon',
    name: 'HistoryCoupon',
    component: () => import('../views/HistoryCoupon.vue'),
    meta: {
      layout: 'grey',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/reward-details',
    name: 'RewardDetails',
    component: () => import('../views/RewardDetail.vue'),
    meta: {
      layout: 'grey',
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/renew-membership',
    name: 'RenewMembership',
    component: () => import('../views/RenewMembership.vue'),
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/howto-get-register-code',
    name: 'HowtoGetRegisterCode',
    component: () => import('../views/HowtoGetRegisterCode.vue')
  },
  {
    path: '/howto-standard-member',
    name: 'HowtoStandardMember',
    component: () => import('../views/HowtoStandardMember.vue')
  },
  {
    path: '/system-coupon-detail/:id',
    name: 'SystemCouponDetail',
    component: () => import('../views/SystemCouponDetail.vue'),
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/coupon-detail/:id',
    name: 'CouponDetail',
    component: () => import('../views/CouponDetail.vue'),
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/example',
    name: 'Example',
    component: () => import('../views/Example.vue'),
    meta: {
      layout: 'login'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

const promise = new Promise(resolve => {
  router.start = resolve
})

router.beforeEach(async (to, from, next) => {
  await promise
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

export default router
