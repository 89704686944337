<template>
  <div id="app">
    <component :is="layout">
      <template v-slot:default>
        <router-view :key="$route.fullPath"/>
      </template>
    </component>
    <b-modal
      scroll="clip"
      class="justify-center align-items-center"
      :active="$wait.is('app loading')"
      :can-cancel="false"
      animation=""
    >
      <loading/>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SimpleLayout from '@/layouts/SimpleLayout'
import LoginLayout from '@/layouts/LoginLayout'
import GreyLayout from '@/layouts/GreyLayout'
import Loading from '@/components/Loading'
import liff from '@line/liff'
export default {
  data () {
    return {
      isLoading: true
    }
  },
  components: {
    'simple-layout': SimpleLayout,
    'grey-layout': GreyLayout,
    'login-layout': LoginLayout,
    Loading
  },
  computed: {
    ...mapState('Auth', ['user']),
    layout () {
      return (this.$route.meta.layout || 'simple') + '-layout'
    }
  },
  methods: {
    ...mapActions({
      updateLineUser: 'Auth/updateLineUser',
      login: 'Auth/login',
      fetchCustomer: 'Auth/fetchCustomer',
      fetchMemberCard: 'Auth/fetchMemberCard',
      editCustomer: 'Auth/editCustomer'
    }),
    async autoLogin () {
      console.log('env', JSON.stringify(process.env))
      console.log(process.env.VUE_APP_PRODUCT_NAME)
      this.$wait.start('app loading')
      try {
        let profile
        let accessToken
        if (process.env.NODE_ENV === 'production') {
          try {
            accessToken = liff.getAccessToken()
            profile = await liff.getProfile()
            this.updateLineUser(profile)
          } catch (error) {
            console.log(error)
            if (liff.isLoggedIn()) {
              liff.logout()
              liff.login()
            }
          }
        } else {
          profile = {
            userId: 'xr1c1',
            pictureUrl: ''
          }
          accessToken = 'xr1c1'
        }
        await this.login({
          line_user_id: profile.userId,
          line_access_token: accessToken
        })
        await this.fetchCustomer()
        if (profile && profile.pictureUrl && profile.displayName) {
          await this.editCustomer({
            line_display_name: (profile && profile.displayName) || '',
            line_picture_url: (profile && profile.pictureUrl) || '',
            first_name: this.user.first_name,
            last_name: this.user.last_name,
            birth_date: this.user.birth_date
          })
        }
        await this.fetchCustomer()
      } catch (e) {
        console.log('error', e)
      } finally {
        this.$wait.end('app loading')
        this.$router.start()
      }
    },
    async initApp () {
      return new Promise(resolve => {
        if (process.env.NODE_ENV === 'production') {
          liff.init({
            liffId: '1655836739-Ema04K32'
          }).then(() => {
            if (!liff.isLoggedIn()) {
              liff.login()
              resolve()
            } else {
              this.autoLogin()
              resolve()
            }
          }).catch((err) => {
            console.log(err)
            this.autoLogin()
            resolve()
          })
        } else {
          this.autoLogin()
          resolve()
        }
      })
    }
  },
  async mounted () {
    await this.initApp()
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';
</style>
